export interface Translation {
  navigation: {
    home: string;
    about: string;
    contact: string;
  };
  welcome: {
    title: string;
    description: string;
  };

  howItWork: {
    step1Title: string;
    step2Title: string;
    step3Title: string;

    step1Content: string;
    step2Content: string;
    step3Content: string;
  }
}

// Create a type that gets all possible nested paths
export type PathsToStringProps<T> = T extends string
  ? []
  : {
    [K in keyof T]: [K, ...PathsToStringProps<T[K]>];
  }[keyof T];

// Convert paths tuple to dot notation
export type Join<T extends string[], D extends string> = T extends []
  ? never
  : T extends [infer F]
  ? F
  : T extends [infer F, ...infer R]
  ? F extends string
  ? `${F}${D}${Join<Extract<R, string[]>, D>}`
  : never
  : string;

// Get all possible translation keys
export type TranslationKeys = Join<PathsToStringProps<Translation>, ".">;

export enum LanguageCode {
  en = "en",
  es = "es",
  he = "he"
};

export interface LanguageState {
  currentLanguage: LanguageCode;
  translations: {
    [key: string]: Translation;
  };
  isLoading: boolean;
  error: string | null;
}