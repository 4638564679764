import React, { FC, useState, useRef, useEffect } from "react";
import { HomeIcon } from "@heroicons/react/24/outline";
import { Procedure } from "hairo/models/enums/procedure.enum";
import ClearDataButton from "./ClearDataButton";

const treatmentOptions: Procedure[] = Object.keys(Procedure) as Procedure[];

export interface TreatmentTypeSelectProps {
  onChange: (data: Procedure) => void;
  fieldClassName?: string;
  shouldShowTooltip: boolean;
  value?: Procedure;
  placeHolder?: string;
  desc?: string;
}

const TreatmentTypeSelect: FC<TreatmentTypeSelectProps> = ({
  onChange,
  fieldClassName = "nc-flex-1.5",
  shouldShowTooltip,
  value,
  placeHolder = "Treatment Type",
  desc = "Select a treatment type"
}) => {
  const [selectedTreatment, setSelectedTreatment] = useState<Procedure | null>(value || null);
  const [showPopover, setShowPopover] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setShowPopover(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelect = (treatment: Procedure) => {
    setSelectedTreatment(treatment);
    onChange(treatment);
    setShowPopover(false);
  };

  return (
    <div className={`relative flex ${fieldClassName}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left ${
          showPopover ? "nc-hero-field-focused" : ""
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <HomeIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow">
          <input
            className="block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate"
            placeholder={placeHolder}
            value={selectedTreatment || ""}
            readOnly
          />
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="line-clamp-1">{!!selectedTreatment ? placeHolder : desc}</span>
          </span>
          {selectedTreatment && (
            <ClearDataButton
              onClick={() => {
                setSelectedTreatment(null);
                onChange("" as any);
              }}
            />
          )}
        </div>
      </div>

      {showPopover && (
        <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {treatmentOptions.map((item) => (
            <span
              key={item}
              onClick={() => handleSelect(item)}
              className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
            >
              <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                {item}
              </span>
            </span>
          ))}
        </div>
      )}
      
      {shouldShowTooltip && (
        <div
          className="absolute left-1/2 transform -translate-x-1/2 top-full mt-2 w-64 p-2 text-center text-white text-sm rounded-md shadow-lg"
          style={{ backgroundColor: '#f36c6c' }}
        >
          Please select a Treatment type before submitting.
          <div
            className="absolute left-1/2 transform -translate-x-1/2 top-[-4px] border-solid border-b-8 border-x-transparent border-x-[8px]"
            style={{ borderBottomColor: '#f36c6c' }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default TreatmentTypeSelect;