import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "hairo/shared/Footer/Footer";
import useWindowSize from "hairo/hooks/useWindowResize";
import { GuardPage } from "./guard-page";
import HomePage from "hairo/components/HomePage/HomePage";
import { ClinicOnBoardingWizardPage } from "hairo/components/ClinicOnBoardingWizardPage/ClinicOnBoardingWizardPage";
import ClinicViewPage from "hairo/components/ClinicsViewPage/ClinicViewPage";
import ClinicPage from "hairo/components/ClinicPage/ClinicPage";
import PageContact from "hairo/components/PageContact/PageContact";
import PageAbout from "hairo/components/PageAbout/PageAbout";
import PageSignUp from "hairo/components/PageSignUp/PageSignUp";
import PageLogin from "hairo/components/PageLogin/PageLogin";
import Page404 from "hairo/components/Page404/Page404";
import FooterNav from "hairo/shared/FooterNav/FooterNav";
import SiteHeader from "hairo/shared/SiteHeader/SiteHeader";
import PageClinicSignUp from "hairo/components/PageClinicSignUp/PageClinicSignUp";

export const pages: Page[] = [
  { path: "/", exact: true, component: HomePage },
  { path: "/#", exact: true, component: HomePage },
  { path: "/clinic-onboarding", exact: true, component: GuardPage as any },
  { path: "/clinic-onboarding-wizard", exact: true, component: ClinicOnBoardingWizardPage },

  { path: "/listing-clinics", component: ClinicViewPage },
  { path: "/listing-clinics/:clinicId/:branchId", component: ClinicPage },

  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/clinic/register/:token", component: PageClinicSignUp },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  //
];

const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <SiteHeader />

      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component;
          return <Route key={path} element={<Component />} path={path} />;
        })}
        <Route element={<Page404 />} />
      </Routes>

      {WIN_WIDTH < 768 && <FooterNav />}
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
