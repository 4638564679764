import React, { useState, useRef, useEffect, FC } from "react";
import ClearDataButton from "./ClearDataButton";
import Countries from "hairo/data/jsons/hairo/countries.json";

export interface LocationInputProps {
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  onChange: (data: any) => void;
  shouldShowTooltip: boolean;
  value: string;
}

const LocationInput: FC<LocationInputProps> = ({
  autoFocus = false,
  placeHolder = "Location",
  desc = "Clinic Location?",
  className = "nc-flex-1.5",
  divHideVerticalLineClass = "left-10 -right-0.5",
  onChange,
  value: propsValue,
  shouldShowTooltip
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const dropdownCountriesSet: Set<string> = new Set();
  for (const country in Countries) {
    dropdownCountriesSet.add(`${country}`);
    Countries[country as keyof typeof Countries].forEach((city: string) => {
      dropdownCountriesSet.add(`${country}, ${city}`);
    });
  }
  const topSuggestion = ["israel, haifa", "israel, Tel Aviv", "israel, Tel Mond", "israel, Ramat Gan"]
  const dropdownCountries: Array<string> = Array.from(dropdownCountriesSet);
  const [value, setValue] = useState(propsValue ?? "");
  const [showPopover, setShowPopover] = useState(autoFocus);
  const [filteredLocations, setFilteredLocations] = useState(topSuggestion);
  const [isInitialSuggestions, setIsInitialSuggestions] = useState(value === "");

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setShowPopover(false);
      }
    };

    if (showPopover) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setValue(inputValue);

    if (inputValue) {
      setIsInitialSuggestions(false);
      const filtered = dropdownCountries.filter(location =>
        location.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredLocations(filtered.slice(0, 5));
    } else {
      setFilteredLocations(topSuggestion);
      setIsInitialSuggestions(true);
    }
  };

  const handleSelectLocation = (item: string) => {
    setValue(item);
    onChange(item);
    setShowPopover(false);
  };

  const renderSearchValue = () => {
    return (
      <>
        {isInitialSuggestions && (
          <div className="px-4 sm:px-8 py-2 font-semibold text-neutral-500 dark:text-neutral-400">
            Top suggestions for you
          </div>
        )}
        {filteredLocations.map((item) => (
          <span
            onClick={() => handleSelectLocation(item)}
            key={item}
            className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
          >
            <span className="block font-medium text-neutral-700 dark:text-neutral-200">
              {item}
            </span>
          </span>
        ))}
      </>
    );
  };

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${showPopover ? "nc-hero-field-focused" : ""
          }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          {/* <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" /> */}
        </div>
        <div className="flex-grow">
          <input
            className="block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate"
            placeholder={placeHolder}
            value={value}
            autoFocus={showPopover}
            onChange={handleInputChange}
            ref={inputRef}
          />
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="line-clamp-1">{!!value ? placeHolder : desc}</span>
          </span>
          {value && (
            <ClearDataButton
              onClick={() => {
                setValue("");
                setFilteredLocations(topSuggestion);
                onChange("");
              }}
            />
          )}
        </div>
      </div>

      {showPopover && (
        <div
          className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 dark:bg-neutral-800 ${divHideVerticalLineClass}`}
        ></div>
      )}

      {showPopover && (
        <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {renderSearchValue()}
        </div>
      )}
      {shouldShowTooltip && (
        <div
          className="absolute left-1/2 transform -translate-x-1/2 top-full mt-2 w-64 p-2 text-center text-white text-sm rounded-md shadow-lg"
          style={{ backgroundColor: '#f36c6c' }}
        >
          Please select a location before submitting.
          <div
            className="absolute left-1/2 transform -translate-x-1/2 top-[-4px] border-solid border-b-8 border-x-transparent border-x-[8px]"
            style={{ borderBottomColor: '#f36c6c' }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default LocationInput;