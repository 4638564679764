import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LanguageCode, LanguageState, Translation } from './types/language';


export const detectUserLanguage = (): LanguageCode => {
  // First check localStorage
  const storedLanguage = localStorage.getItem('preferredLanguage') as LanguageCode;
  if (storedLanguage) {
    return storedLanguage;
  }

  // Then try to get browser/system language
  const browserLang = navigator.language.split('-')[0];

  // Map of country codes to supported languages
  const countryToLanguage: Record<string, LanguageCode> = {
    USA: LanguageCode.es,
    Spain: LanguageCode.en,
    Israel: LanguageCode.he,
    // Add more country-language mappings as needed
  };

  // Try to get user's country using their timezone
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userCountry = timeZone.split('/')[1]?.toUpperCase();
  console.log("userCountry");
  console.log(userCountry);
  console.log(timeZone.split('/'));
  // If we have a mapping for their country, use that language
  if (userCountry && countryToLanguage[userCountry]) {
    return countryToLanguage[userCountry];
  }

  // If browser language matches our supported languages, use that
  console.log("browserLang");
  console.log(browserLang);
  console.log(Object.values(LanguageCode));
  if (Object.values(LanguageCode).includes(browserLang as LanguageCode)) {
    console.log("found!");
    return browserLang as LanguageCode;
  }

  // Default to English if no matches
  return LanguageCode.en;
};

const initialState: LanguageState = {
  currentLanguage: detectUserLanguage(),
  translations: {},
  isLoading: false,
  error: null
};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<LanguageCode>) => {
      state.currentLanguage = action.payload;
      localStorage.setItem('preferredLanguage', action.payload);
      document.documentElement.lang = action.payload;
    },
    setTranslations: (state, action: PayloadAction<{ language: LanguageCode; data: Translation }>) => {
      state.translations = {
        ...state.translations,
        [action.payload.language]: action.payload.data
      };
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    }
  }
});

export const { setLanguage, setTranslations, setLoading, setError } = languageSlice.actions;
export default languageSlice.reducer;