import React, { FC, useEffect } from "react";
import { Link } from "react-router-dom";

interface Props {
  className?: string;
  onClick?: () => void;
  href?: string;
}
const ButtonSubmit: FC<Props> = ({
  className = "",
  onClick = () => { },
  href = "/listing-clinics"
}) => {


  return (
    <div
      onClick={onClick}
      className={`flex-shrink-0 px-4 py-2.5 cursor-pointer rounded-xl bg-primary-6000 flex items-center justify-center text-neutral-50 focus:outline-none ${className} relative z-20`}
    >
      <span className="ml-2">Search</span>
    </div>
  );
};

export default ButtonSubmit;
