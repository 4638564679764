import { useSelector } from 'react-redux';
import { Translation, TranslationKeys } from '../types/language';
import { RootState } from 'hairo/store/store';

export function useTranslation() {
  const { currentLanguage, translations, isLoading, error } = useSelector(
    (state: RootState) => state.language
  );

  const getNestedValue = (obj: any, path: string): string => {
    return path.split('.').reduce((current, key) => {
      return current && current[key];
    }, obj) as string;
  };
  
  const translator = (key: TranslationKeys): string => {
    try {
      const translation = translations[currentLanguage];
      if (!translation) {
        return key;
      }
      
      const value = getNestedValue(translation, key);
      return value || key;
    } catch (error) {
      console.warn(`Translation key "${key}" not found for language "${currentLanguage}"`);
      return key;
    }
  };

  return { translator, isLoading, error };
}