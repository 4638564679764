import { useState } from "react";
import Input from "hairo/shared/Input/Input";
import Button from "hairo/shared/Button/Button";
import "./SettingsDetails.style.scss";

export const SettingsDetailsComponent = () => {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
    });

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <div className="settingsDetails">
            <form className="grid grid-cols-1 gap-6" onSubmit={() => null}>
                <label className="flex" style={{ alignItems: "center" }}>
                    <span className="text-neutral-800 dark:text-neutral-200 whitespace-nowrap">First Name</span>
                    <Input type="text" name="firstName" className="mt-1" onChange={handleChange} />
                </label>
                <label className="flex" style={{ alignItems: "center" }}>
                    <span className="text-neutral-800 dark:text-neutral-200 whitespace-nowrap">Last Name</span>
                    <Input type="text" name="lastName" className="mt-1" onChange={handleChange} />
                </label>
                <label className="flex" style={{ alignItems: "center" }}>
                    <span className="text-neutral-800 dark:text-neutral-200 whitespace-nowrap">Phone Number</span>
                    <Input type="text" name="phoneNumber" className="mt-1" onChange={handleChange} />
                </label>
                <label className="flex" style={{ alignItems: "center" }}>
                    <span className="text-neutral-800 dark:text-neutral-200 whitespace-nowrap">Email address</span>
                    <Input type="email" name="email" placeholder="example@example.com" className="mt-1" onChange={handleChange} />
                </label>
                <label className="flex" style={{ alignItems: "center" }}>
                    <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200 whitespace-nowrap">Password</span>
                    <Input type="password" name="password" className="mt-1" onChange={handleChange} />
                </label>
                <Button
                    className={`ttnc-ButtonPrimary disabled:bg-opacity-70 bg-black hover:bg-primary-700 text-neutral-50`}
                    type="submit"
                >
                    Save Changes
                </Button>
            </form>
        </div>
    )
}