import { useEffect } from "react";

import { TaxonomyType } from "hairo/data/types";
import HomePageSearchSection from "hairo/components/HomePage/HomePageSearchSerction/HomePageSearchSection";
import HIW1img from "hairo/images/avatars/no-hair.svg";
import HIW2img from "hairo/images/avatars/many-hair.svg";
import HIW3img from "hairo/images/avatars/hair.svg";
import HIW1imgDark from "hairo/images/HIW2-1-dark.png";
import HIW2imgDark from "hairo/images/HIW2-2-dark.png";
import HIW3imgDark from "hairo/images/HIW2-3-dark.png";
import BackgroundContainer from "hairo/shared/BackgroundContainer/BackgroundContainer";
import SectionHowItWork from "./HowItWorkSection/SectionHowItWork";
import ContactUsComponent from "../../shared/ContactUs/contanct-us.component";
import SectionGridFeatureProperty from "./SectionGridFeatureClinics/SectionGridFeatureClinics";
import { useTranslation } from "hairo/store/language/hook/useTranslation";

const DEMO_CATS_2: TaxonomyType[] = [
  {
    id: "1",
    href: "/listing-stay",
    name: "Enjoy the great cold",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/5764100/pexels-photo-5764100.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "222",
    href: "/listing-stay",
    name: "Sleep in a floating way",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/2869499/pexels-photo-2869499.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "3",
    href: "/listing-stay",
    name: "In the billionaire's house",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "4",
    href: "/listing-stay",
    name: "Cool in the deep forest",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/247532/pexels-photo-247532.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "5",
    href: "/listing-stay",
    name: "In the billionaire's house",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];

function HomePage() {
  const { translator } = useTranslation();

  useEffect(() => {
    const $body = document.querySelector("body");
    if (!$body) return;
    $body.classList.add("theme-cyan-blueGrey");
    return () => {
      $body.classList.remove("theme-cyan-blueGrey");
    };
  }, []);

  return (
    <div className="nc-PageHome2 relative overflow-hidden">
      {/* GLASSMOPHIN */}
      {/* <BgGlassmorphism /> */}

      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        <HomePageSearchSection className="" />

        {/* SECTION */}
        <SectionHowItWork
          data={[
            {
              id: 1,
              img: HIW1img,
              imgDark: HIW1imgDark,
              title: translator("howItWork.step1Title"),
              desc: "Choose the treament you are looking for, price range, and location. Our app will find you the perfect match.",
            },
            {
              id: 2,
              img: HIW2img,
              imgDark: HIW2imgDark,
              title: "Choose property",
              desc: "From the number of options our app will provide, you can select any property that you like to explore.",
            },
            {
              id: 3,
              img: HIW3img,
              imgDark: HIW3imgDark,
              title: "Book you property",
              desc: "Find a home or space from our search bar. Enter your specific location, property type and price range.",
            },
          ]}
        />

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundContainer />
          <SectionGridFeatureProperty />
        </div>

        {/* SECTION2 */}
        {/* <SectionOurFeatures type="type2" rightImg={rightImgPng} /> */}

        {/* SECTION */}
        {/* <SectionDowloadApp /> */}

        {/* SECTION 1 */}
        {/* <SectionSliderNewCategories
          categories={DEMO_CATS_2}
          categoryCardType="card4"
          itemPerRow={4}
          heading="Suggestions for discovery"
          subHeading="Popular places to stay that Chisfis recommends for you"
          uniqueClassName="PageHome2_s1"
        /> */}

        {/* SECTION */}
        {/* <div className="relative py-16">
          <BackgroundSection className="bg-neutral-100 dark:bg-black dark:bg-opacity-20 " />
          <SectionGridAuthorBox boxCard="box2" />
        </div> */}

        {/* SECTION 1 */}
        {/* <SectionSliderNewCategories
          heading="Explore by types of stays"
          subHeading="Explore houses based on 10 types of stays"
          categoryCardType="card5"
          itemPerRow={5}
          uniqueClassName="PageHome2_s2"
        /> */}

        {/* SECTION */}
        <ContactUsComponent />
      </div>
    </div>
  );
}

export default HomePage;
