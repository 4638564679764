import React, { FC, useEffect, useState } from "react";
import Pagination from "hairo/shared/Pagination/Pagination";
import TabFilters from "./ClinicFilter/TabFilters";
import { Clinic } from "hairo/models/response/clinics.response";
import { ClinicsRequest } from "hairo/models/request/clinics.request";
import Heading2 from "hairo/shared/Heading/Heading2";
import ClinicCard from "./ClinicCard/ClinicCard";
import HomePageSearchForm from "hairo/components/HomePage/HomePageSearchSerction/HomePageSearchForm/HomePageSearchForm";
import HeroSearchForm2Mobile from "hairo/components/HomePage/HomePageSearchSerction/HomePageSearchForm/HeroSearchForm2Mobile/HeroSearchForm2Mobile";

export interface SectionGridFilterCardProps {
  className?: string;
  data: Clinic[];
  filters: ClinicsRequest
}

const ClinicsView: FC<SectionGridFilterCardProps> = (props: SectionGridFilterCardProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState<Clinic[]>([]);
  const ITEMS_PER_PAGE = 2;

  useEffect(() => {
    if (props.data) {
      const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
      const endIndex = startIndex + ITEMS_PER_PAGE;

      // Slice the data array to get the current page data
      const pageData = props.data.slice(startIndex, endIndex);

      // Update the currentData state with the sliced data
      setCurrentData(pageData);
    }
  }, [props.data, currentPage])

  return (
    <div className={`nc-SectionGridFilterCard ${props.className}`} data-nc-id="SectionGridFilterCard">
      <div className="hidden lg:block lg:mb-20 w-full">
        <HomePageSearchForm />
      </div>
      <div className="block lg:hidden mb-20">
        <HeroSearchForm2Mobile />
      </div>
      <Heading2
        heading={`Clinics in ${props.filters.country}`}
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            233 Property
            <span className="mx-2">·</span>
            Aug 12 - 18
          </span>
        }
      />

      <div className="mb-8 lg:mb-11">
        <TabFilters filters={props.filters} />
      </div>
      {
        props.data.length == 0 ?
          <div>no data matching your search :(</div> :
          <div>
            <div className="grid grid-cols-1 gap-6 md:gap-8 xl:grid-cols-2 ">
              {currentData.map((clinic: Clinic) => (
                <ClinicCard key={clinic._id} data={clinic} />
              ))}
            </div>
            <div className="flex mt-16 justify-center items-center">
              {props.data && <Pagination pages={props.data.length / 2} onPageChanged={(page: number) => setCurrentPage(page)} />}
            </div>
          </div>
      }
    </div>
  );
};

export default ClinicsView;
