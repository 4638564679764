import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "hairo/store/store";
import { ClinicsFilters, setClinicsFilters } from "hairo/store/global-states/global-states.slice";
import { Procedure } from "hairo/models/enums/procedure.enum";
import { useNavigate } from "react-router-dom";
import LocationInput from "hairo/components/inputs/concrets/LocationInput";
import TreatmentTypeSelect from "hairo/components/inputs/concrets/TreatmentTypeSelect";
import PriceRangeInput from "hairo/components/inputs/concrets/PriceRangeInput";

export interface HairTransplantSearchFormProps {
  clinicFilters?: ClinicsFilters
}

const HairTransplantSearchForm: FC<HairTransplantSearchFormProps> = (props:HairTransplantSearchFormProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const router = useNavigate();
  const [shouldShowLocationTooltip, setShouldShowLocationTooltip] = useState(false);
  const [shouldShowTreatmentTooltip, setShouldShowTreatmentTooltip] = useState(false);
  const [formData, setFormData] = useState<ClinicsFilters>({
    location: props.clinicFilters?.location ?? "",
    treatmentType: props.clinicFilters?.treatmentType ?? "" as any,
    priceRange: props.clinicFilters?.priceRange ??[0, 0],
  });

  useEffect(() => {
    console.log(formData.location);
    if (formData.location) {
      setShouldShowLocationTooltip(false);
    }
  }, [formData.location]);

  useEffect(() => {
    console.log(formData.treatmentType);
    if (formData.treatmentType) {
      setShouldShowTreatmentTooltip(false);
    }
  }, [formData.treatmentType]);

  const renderForm = () => {
    return (
      <form className="w-full relative xl:mt-8 flex flex-col lg:flex-row lg:items-center rounded-3xl lg:rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 divide-y divide-neutral-200 dark:divide-neutral-700 lg:divide-y-0">
        <LocationInput value={props.clinicFilters?.location ?? ""} onChange={(location: string) => setFormData({ ...formData, location: location })} shouldShowTooltip={shouldShowLocationTooltip} className="flex-[1.5]" />

        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <TreatmentTypeSelect value={props.clinicFilters?.treatmentType} onChange={(treatmentType: Procedure) => setFormData({ ...formData, treatmentType: treatmentType })} shouldShowTooltip={shouldShowTreatmentTooltip} />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <PriceRangeInput value={props.clinicFilters?.priceRange} isSearchVisible={true} onChange={(price: [number, number]) => setFormData({ ...formData, priceRange: price })} onSearch={() => {
          console.log(formData)
          if (!formData.location || !formData.treatmentType) {
            console.log("empty data");
            setShouldShowLocationTooltip(!formData.location);
            setShouldShowTreatmentTooltip(!formData.treatmentType)
          } else{
            setShouldShowLocationTooltip(false);
            setShouldShowTreatmentTooltip(false)
            dispatch(setClinicsFilters(formData))
            console.log(formData);
            router("/listing-clinics");
          }
        }} />
      </form>
    );
  };

  return renderForm();
};

export default HairTransplantSearchForm;
