
import { Card, CardContent, CardHeader, Checkbox } from '@mui/material';
import Input from '@mui/material/Input';
import Label from 'hairo/shared/Label/Label';
import React, { useState, useEffect } from 'react';

export interface PriceError {
    required?: string;
    number?: string;
    min?: string;
    max?: string;
    range?: string;
}

export interface PriceErrors {
    [key: string]: PriceError;
}

export interface BaseTreatment {
    enabled: boolean;
    errors: PriceErrors;
}

export interface TransplantTreatment extends BaseTreatment {
    pricePerFollicle: string;
    priceRangeMin: string;
    priceRangeMax: string;
}

export interface SessionTreatment extends BaseTreatment {
    pricePerSession: string;
}

export interface TreatmentsState {
    hair: TransplantTreatment;
    beard: TransplantTreatment;
    prp: SessionTreatment;
    prgf: SessionTreatment;
}

export interface TreatmentPricingFormProps {
    onUpdate?: (treatments: TreatmentsState) => void;
    onValidationChange?: (isValid: boolean) => void;
}

type PriceField = 'pricePerFollicle' | 'priceRangeMin' | 'priceRangeMax' | 'pricePerSession';
type TreatmentType = keyof TreatmentsState;

const TreatmentPricingForm: React.FC<TreatmentPricingFormProps> = ({ onUpdate, onValidationChange }) => {
    const [hasEnabledTreatments, setHasEnabledTreatments] = useState(false);
    const [treatments, setTreatments] = useState<TreatmentsState>({
        hair: {
            enabled: false,
            pricePerFollicle: '',
            priceRangeMin: '',
            priceRangeMax: '',
            errors: {}
        },
        beard: {
            enabled: false,
            pricePerFollicle: '',
            priceRangeMin: '',
            priceRangeMax: '',
            errors: {}
        },
        prp: {
            enabled: false,
            pricePerSession: '',
            errors: {}
        },
        prgf: {
            enabled: false,
            pricePerSession: '',
            errors: {}
        }
    });

    const validateField = (
        value: string, 
        field: PriceField,
        treatmentType: TreatmentType
    ): PriceError => {
        const errors: PriceError = {};
    
        if (value === '') {
            errors.required = 'This field is required';
        } else {
            const numValue = parseFloat(value);
            if (isNaN(numValue)) {
                errors.number = 'Must be a valid number';
            } else if (numValue < 0) {
                errors.min = 'Price cannot be negative';
            } else if (numValue > 100000) {
                errors.max = 'Price seems unusually high';
            }
    
            // Add range validation for min/max prices
            if ((field === 'priceRangeMin' || field === 'priceRangeMax') && 
                ('priceRangeMin' in treatments[treatmentType])) {
                const treatment = treatments[treatmentType] as TransplantTreatment;
                const min = field === 'priceRangeMin' ? numValue : parseFloat(treatment.priceRangeMin);
                const max = field === 'priceRangeMax' ? numValue : parseFloat(treatment.priceRangeMax);
                
                if (!isNaN(min) && !isNaN(max) && min > max) {
                    errors.range = 'Minimum price cannot be greater than maximum';
                }
            }
        }
    
        return errors;
    };

    const handleTreatmentToggle = (treatmentType: keyof TreatmentsState): void => {
        setTreatments(prev => {
            const newTreatments = {
                ...prev,
                [treatmentType]: {
                    ...prev[treatmentType],
                    enabled: !prev[treatmentType].enabled,
                    errors: {}
                }
            };

            // Check if any treatment is enabled
            const hasEnabled = Object.values(newTreatments).some(t => t.enabled);
            setHasEnabledTreatments(hasEnabled);

            return newTreatments;
        });
    };

    const handlePriceChange = (
        treatmentType: keyof TreatmentsState,
        field: PriceField,
        value: string
    ) => {
        if (value === '' || /^\d*\.?\d*$/.test(value)) {
            const errors = validateField(value, field, treatmentType);

            setTreatments(prev => ({
                ...prev,
                [treatmentType]: {
                    ...prev[treatmentType],
                    [field]: Number.parseFloat(value),
                    errors: {
                        ...prev[treatmentType].errors,
                        [field]: errors
                    }
                }
            }));
        }
    };

    const validateAll = (): boolean => {
        let newTreatments = { ...treatments };
        let isValid = true;

        // If no treatments are enabled, form is invalid
        if (!hasEnabledTreatments) {
            isValid = false;
        } else {
            Object.entries(treatments).forEach(([type, treatment]) => {
                const treatmentType = type as keyof TreatmentsState;
                if (treatment.enabled) {
                    Object.keys(treatment).forEach(field => {
                        if (field !== 'enabled' && field !== 'errors') {
                            const priceField = field as PriceField;
                            const value = treatment[priceField];
                            if (typeof value === 'string') {
                                const errors = validateField(value, priceField, treatmentType);
                                if (Object.keys(errors).length > 0) {
                                    isValid = false;
                                }
                                newTreatments[treatmentType].errors[priceField as string] = errors;
                            }
                        }
                    });

                    // Check if all required fields have values
                    if (treatment.enabled) {
                        const requiredFields = type === 'prp' || type === 'prgf'
                            ? ['pricePerSession']
                            : ['pricePerFollicle', 'priceRangeMin', 'priceRangeMax'];

                        requiredFields.forEach(field => {
                            if (!treatment[field as keyof typeof treatment]) {
                                isValid = false;
                            }
                        });
                    }
                }
            });
        }
        setTreatments(newTreatments);

        if (onValidationChange) {
            onValidationChange(isValid);
        }

        return isValid;
    };

    useEffect(() => {
        if (onUpdate && validateAll()) {
            onUpdate(treatments);
        }
    }, [treatments]);

    const renderPriceInput = (
        treatmentType: keyof TreatmentsState,
        field: PriceField,
        label: string
    ) => {
        const treatment = treatments[treatmentType];
        const value = treatment[field as keyof typeof treatment];

        return (
            <div className="grid grid-cols-2 gap-4">
                <Label>{label}</Label>
                <div className="space-y-1">
                    <Input
                        id={`${treatmentType}-${field}`}
                        type="number"
                        value={value}
                        onChange={(e) => handlePriceChange(treatmentType, field, e.target.value)}
                        placeholder="0.00"
                        className={
                            Object.keys(treatment.errors[field] || {}).length > 0
                                ? 'border-red-500'
                                : ''
                        }
                    />
                    {Object.values(treatment.errors[field] || {}).map((error, index) => (
                        <p key={index} className="text-red-500 text-sm">{error}</p>
                    ))}
                </div>
            </div>
        );
    };

    return (
        <div className="max-w-[1400px] mx-auto space-y-4">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                <div className="text-center">
                    <h1 className="text-2xl font-semibold">Treatment Pricing Setup</h1>
                </div>
                <div className="text-center">
                    <h1 className="text-2xl font-semibold">Preview</h1>
                </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                <div className="h-[700px] flex flex-col">
                    <Card className="w-full flex-1 flex flex-col">
                        <CardHeader className="border-b pb-4 shrink-0">
                            <p className="text-neutral-500 dark:text-neutral-400">
                                Select treatments and set their prices
                            </p>
                        </CardHeader>
                        <div className="flex-1 overflow-y-auto px-6">
                            {/* Hair Transplant Section */}
                            <div className="py-4 first:pt-6 border-b">
                                <div className="flex items-center space-x-2 mb-4">
                                    <Checkbox
                                        id="hairTransplant"
                                        checked={treatments.hair.enabled}
                                        onChange={() => handleTreatmentToggle('hair')}
                                    />
                                    <Label className="font-medium text-lg">
                                        Hair Transplant Services
                                    </Label>
                                </div>

                                {treatments.hair.enabled && (
                                    <div className="space-y-4 mt-4">
                                        {renderPriceInput('hair', 'pricePerFollicle', 'Price Per Follicle ($)')}
                                        {renderPriceInput('hair', 'priceRangeMin', 'Minimum Price ($)')}
                                        {renderPriceInput('hair', 'priceRangeMax', 'Maximum Price ($)')}
                                    </div>
                                )}
                            </div>

                            <div className="py-4 border-b">
                                <div className="flex items-center space-x-2 mb-4">
                                    <Checkbox
                                        id="beardTransplant"
                                        checked={treatments.beard.enabled}
                                        onChange={() => handleTreatmentToggle('beard')}
                                    />
                                    <Label className="font-medium text-lg">
                                        Beard Transplant Services
                                    </Label>
                                </div>

                                {treatments.beard.enabled && (
                                    <div className="space-y-4 mt-4">
                                        {renderPriceInput('beard', 'pricePerFollicle', 'Price Per Follicle ($)')}
                                        {renderPriceInput('beard', 'priceRangeMin', 'Minimum Price ($)')}
                                        {renderPriceInput('beard', 'priceRangeMax', 'Maximum Price ($)')}
                                    </div>
                                )}
                            </div>

                            <div className="py-4 border-b">
                                <div className="flex items-center space-x-2 mb-4">
                                    <Checkbox
                                        id="prpTreatment"
                                        checked={treatments.prp.enabled}
                                        onChange={() => handleTreatmentToggle('prp')}
                                    />
                                    <Label className="font-medium text-lg">
                                        PRP Treatment
                                    </Label>
                                </div>

                                {treatments.prp.enabled && (
                                    <div className="space-y-4 mt-4">
                                        {renderPriceInput('prp', 'pricePerSession', 'Price Per Session ($)')}
                                    </div>
                                )}
                            </div>

                            <div className="py-4">
                                <div className="flex items-center space-x-2 mb-4">
                                    <Checkbox
                                        id="prgfTreatment"
                                        checked={treatments.prgf.enabled}
                                        onChange={() => handleTreatmentToggle('prgf')}
                                    />
                                    <Label className="font-medium text-lg">
                                        PRGF Treatment
                                    </Label>
                                </div>

                                {treatments.prgf.enabled && (
                                    <div className="space-y-4 mt-4">
                                        {renderPriceInput('prgf', 'pricePerSession', 'Price Per Session ($)')}
                                    </div>
                                )}
                            </div>
                        </div>
                    </Card>
                </div>

                <div className="h-[700px] flex flex-col">
                    <Card className="w-full flex-1 flex flex-col">
                        <CardHeader className="border-b pb-4 shrink-0">
                            <p className="text-neutral-500 dark:text-neutral-400">
                                Prices may increase on weekends or holidays
                            </p>
                        </CardHeader>
                        <div className="flex-1 overflow-y-auto px-6 py-6">
                            {!Object.values(treatments).some(t => t.enabled) ? (
                                <p className="text-neutral-500 dark:text-neutral-400 text-center">
                                    Select treatments and set prices to see the preview
                                </p>
                            ) : (
                                <div className="space-y-4">
                                    {treatments.hair.enabled && (
                                        <>
                                            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
                                                <span>Hair Transplant Per Follicle</span>
                                                <span>{treatments.hair.pricePerFollicle || 0}$</span>
                                            </div>
                                            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
                                                <span>Hair Transplant min range</span>
                                                <span>{treatments.hair.priceRangeMin || 0}$</span>
                                            </div>
                                            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
                                                <span>Hair Transplant max range</span>
                                                <span>{treatments.hair.priceRangeMax || 0}$</span>
                                            </div>
                                        </>
                                    )}

                                    {treatments.beard.enabled && (
                                        <>
                                            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
                                                <span>Beard Transplant Per Follicle</span>
                                                <span>{treatments.beard.pricePerFollicle || 0}$</span>
                                            </div>
                                            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
                                                <span>Beard Transplant min range</span>
                                                <span>{treatments.beard.priceRangeMin || 0}$</span>
                                            </div>
                                            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
                                                <span>Beard Transplant max range</span>
                                                <span>{treatments.beard.priceRangeMax || 0}$</span>
                                            </div>
                                        </>
                                    )}

                                    {treatments.prp.enabled && (
                                        <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
                                            <span>PRP Treatment (Per Session)</span>
                                            <span>{treatments.prp.pricePerSession || 0}$</span>
                                        </div>
                                    )}

                                    {treatments.prgf.enabled && (
                                        <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
                                            <span>PRGF Treatment (Per Session)</span>
                                            <span>{treatments.prgf.pricePerSession || 0}$</span>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default TreatmentPricingForm;