import hairAnimation from "hairo/images/avatars/hair-animation.gif";

export const HairoLoadingComponent = () => {
    return (
        <div style={{ display: "flex", height: "100%", flexDirection: "column" }}>
            <div style={{ margin: "auto auto" }}>
                <img src={hairAnimation} alt="hair animation gif" />
                <div>Loading...</div>
            </div>
        </div>
    )
} 