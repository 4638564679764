import React, { FC, useEffect, useRef, useState } from "react";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import ClinicsView from "./ClinicViewSection/ClinicsViewSection";
import { ClinicsRequest } from "hairo/models/request/clinics.request";
import { Clinic } from "hairo/models/response/clinics.response";
import { useSelector } from "react-redux";
import { RootState } from "hairo/store/store";
import { SortBy } from "hairo/models/enums/sortby.enum";
import { ClinicsService } from "hairo/services/clinics.service";
import ContactUsComponent from "hairo/shared/ContactUs/contanct-us.component";

export interface ListingRealEstatePageProps {
  className?: string;
}

const ClinicViewPage: FC<ListingRealEstatePageProps> = ({ className = "" }) => {
  const [data, setData] = useState<Clinic[]>([]);
  const [clinicRequest, setClinicRequest] = useState<ClinicsRequest>();
  const LIMIT_PAGE = 50; //TODO: talk about magic numbers;
  const currentClinicsFiltes = useSelector((state: RootState) => state.globalStates.clinicsFilters);
  const preferredCurrency = useSelector((state: RootState) => state.globalStates.preferredCurrency);
  const clinicsService = useRef(new ClinicsService()).current;

  useEffect(() => {
    const [country, city] = currentClinicsFiltes.location.split(",");
    setClinicRequest({
      country: country,
      city: city?.trimStart(),
      minRangePrice: currentClinicsFiltes.priceRange[0],
      maxRangePrice: currentClinicsFiltes.priceRange[1],
      offset: 0,
      procedure: currentClinicsFiltes.treatmentType,
      sortBy: SortBy.ASC,
      limit: LIMIT_PAGE,
      preferredCurrency: preferredCurrency
    })
  }, [currentClinicsFiltes])

  useEffect(() => {
    const $body = document.querySelector("body");
    if ($body) {
      $body.className = "theme-cyan-blueGrey";
    }

    const asyncCallback = async () => {
      if (clinicRequest) {
        const clinics: Clinic[] = await clinicsService.getClinics(clinicRequest) ?? []
        console.log("response data");
        console.log(data);
        setData(clinics);
      }
    }

    asyncCallback();

    return () => {
      if ($body) {
        $body.className = "";
      }
    };
  }, [clinicRequest]);

  return (
    <div
      className={`nc-ListingRealEstatePage relative overflow-hidden ${className}`}
      data-nc-id="ListingRealEstatePage"
    >


      <div className="container relative">
        {/* SECTION HERO */}
        {/* <SectionHero2ArchivePage className="" /> */}

        {/* SECTION */}
        {
          clinicRequest &&
          <ClinicsView className="py-24 lg:py-28" data={data} filters={clinicRequest} />
        }

        {/* SECTION 1 */}
        <div className="relative py-16">
          <BackgroundSection />
          {/* <SectionSliderNewCategories
            heading="Explore top destination ✈"
            subHeading="Explore thousands of destinations around the world"
            categoryCardType="card4"
            itemPerRow={4}
            categories={DEMO_CATS}
            sliderStyle="style2"
            uniqueClassName="nc-ListingRealEstatePage"
          /> */}
        </div>

        {/* SECTION */}
        <ContactUsComponent className="py-24 lg:py-28" />
      </div>
    </div>
  );
};

export default ClinicViewPage;
