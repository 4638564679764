import { FC, useMemo, useState } from "react";
import { useWizard } from "react-use-wizard";
import Input from "hairo/shared/Input/Input";
import { onboardingWizardProps } from "../ClinicOnBoardingWizardPage";
import { ClinicsOnboardingRequest } from "hairo/models/request/clinics-onboarding.request";


export const OnBoardingStep1Details: FC<onboardingWizardProps> = (props: onboardingWizardProps) => {
    const { handleStep, previousStep, nextStep } = useWizard();
    const [formData, setFormData] = useState<Partial<ClinicsOnboardingRequest>>({
        name: props.onboardingRequest.name ?? "",
        description: props.onboardingRequest.description ?? "",
        yearsOfActivity: props.onboardingRequest.yearsOfActivity ?? undefined,
        contacts: props.onboardingRequest.contacts ?? [{
            countries: ["Israel"],
            email: ""
        }]
    });

    const isFromValid = useMemo(() => {
        console.log(formData);
        return formData.name != "" && formData.description != "" && !!formData.yearsOfActivity && formData.contacts?.[0].email != "";
    }, [formData])

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        if (name == "contacts") {
            setFormData({
                ...formData,
                contacts: [{
                    countries: ["Israel"],
                    email: value
                }],
            });
            return;
        }
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <>
            <div className="title">
                Clinic Details
            </div>
            {/* <button onClick={() => previousStep()}>Previous ⏮️</button> */}
            <form className="grid grid-cols-1 gap-6" onSubmit={() => {
                props.setOnboardingRequest({ ...props.onboardingRequest, ...formData })
                nextStep();
            }}>
                <label className="flex" style={{ alignItems: "center" }}>
                    <span className="text-neutral-800 dark:text-neutral-200 whitespace-nowrap">Name</span>
                    <Input value={formData.name} type="text" name="name" className="mt-1" onChange={handleChange} />
                </label>
                <label className="flex" style={{ alignItems: "center" }}>
                    <span className="text-neutral-800 dark:text-neutral-200 whitespace-nowrap">Description</span>
                    <Input value={formData.description} type="text" name="description" className="mt-1" onChange={handleChange} />
                </label>
                <label className="flex" style={{ alignItems: "center" }}>
                    <span className="text-neutral-800 dark:text-neutral-200 whitespace-nowrap">yearsOfActivity</span>
                    <Input value={formData.yearsOfActivity} type="number" name="yearsOfActivity" className="mt-1" onChange={handleChange} />
                </label>
                <label className="flex" style={{ alignItems: "center" }}>
                    <span className="text-neutral-800 dark:text-neutral-200 whitespace-nowrap">Email address</span>
                    <Input value={formData.contacts?.[0]?.email} type="email" name="contacts" placeholder="example@example.com" className="mt-1" onChange={handleChange} />
                </label>
                <button
                    className={`wizardBtn ttnc-ButtonPrimary disabled:bg-opacity-70 hover:bg-primary-700 text-neutral-50`}
                    type="submit"
                    disabled={!isFromValid}
                >
                    Next
                </button>
            </form>
            {/* <button onClick={() => nextStep()}>Next ⏭</button> */}
        </>
    );
}