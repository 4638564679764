import React, { FC, useEffect, useState } from "react";
import facebookSvg from "hairo/images/Facebook.svg";
import twitterSvg from "hairo/images/Twitter.svg";
import googleSvg from "hairo/images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "hairo/shared/Input/Input";
import ButtonPrimary from "hairo/shared/Button/ButtonPrimary";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export interface PageClinicSignUpProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "https://api.haironow.com/api/user/auth/google",
    icon: googleSvg,
  },
];

const PageClinicSignUp: FC<PageClinicSignUpProps> = ({ className = "" }) => {
  const location = useLocation();
  const router = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    token: ""
  });

  useEffect(() => {
    const urlParts = location.pathname.split("/");
    const token = urlParts[urlParts.length - 1];
    setFormData({ ...formData, token: token })
  }, [location])

  const handleSubmit = async (event: any) => {
    event.preventDefault(); // Prevent default form submission

    // Example: Adding additional field

    try {
      const response = await fetch("https://api.haironow.com/api/clinic/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: formData.email,
          name: formData.firstName,
          password: formData.password,
          verifyPassword: formData.password,
          phoneNumber: formData.phoneNumber,
          token: formData.token
        }),
      });
      const responseData = await response.json();
      // Handle response as needed
      if (responseData.result) {
        console.log("Response:", responseData);
        toast.success("Register done successfully", { position: "bottom-left", autoClose: 1000 })
        router("/login")
        
      } else {
        toast.error(responseData.additional as string, { position: "bottom-left", autoClose: 1000 })
      }

    } catch (error) {
      console.error("Error:", error);
      toast.error(error as string, { position: "bottom-left", autoClose: 1000 })
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">Signup</h2>
        <div className="max-w-md mx-auto space-y-6 ">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a key={index} href={item.href} className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]">
                <img className="flex-shrink-0" src={item.icon} alt={item.name} />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">{item.name}</h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">OR</span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">First Name</span>
              <Input type="text" name="firstName" className="mt-1" onChange={handleChange} />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">Last Name</span>
              <Input type="text" name="lastName" className="mt-1" onChange={handleChange} />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">Phone Number</span>
              <Input type="text" name="phoneNumber" className="mt-1" onChange={handleChange} />
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">Email address</span>
              <Input type="email" name="email" placeholder="example@example.com" className="mt-1" onChange={handleChange} />
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">Password</span>
              <Input type="password" name="password" className="mt-1" onChange={handleChange} />
            </label>
            <ButtonPrimary type="submit">Continue</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="/login">Sign in</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageClinicSignUp;
