import PageLogin from "containers/PageLogin/PageLogin";
import { LoginService } from "hairo/services/login.service"
import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux";
import { RootState } from "hairo/store/store";
import { useNavigate } from "react-router-dom";
import { HairoLoadingComponent } from "hairo/shared/HairoLoading/hairo-loading.component";
import ClinicProfilePage from "hairo/components/ClinicOnBoardingPage/ClinicOnBoaringPage";

function GuardPage() {
    const loginService = useRef(new LoginService()).current;
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const isClinic = useSelector((state: RootState) => state.globalStates.isClinic);
    const router = useNavigate();

    useEffect(() => {
        initialCallback();
    }, [])

    const initialCallback = async () => {
        const response = await loginService.isLoggedIn()
        if (response) {
            setIsLoggedIn(true);
        }
        setIsLoaded(true);
    }

    return (
        isLoaded ? (
            isClinic ?
                isLoggedIn ? <ClinicProfilePage /> : <PageLogin /> :
                router('/')
        ) : (
            <HairoLoadingComponent />
        )
    )
}

export { GuardPage };