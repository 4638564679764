import React, { FC, useEffect, useState } from "react";
import GallerySlider from "hairo/shared/GallerySlider/GallerySlider";
import StartRating from "hairo/shared/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "hairo/shared/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "hairo/shared/SaleOffBadge/SaleOffBadge";
import Badge from "hairo/shared/Badge/Badge";
import ClinicsMock from "hairo/data/jsons/hairo/clinics.json";
import { Clinic } from "hairo/models/response/clinics.response";
import { useDispatch } from "react-redux";

export interface ClinicCardProps {
  className?: string;
  data?: Clinic;
}

const ClinicCard: FC<ClinicCardProps> = ({ className = "", data = ClinicsMock[0] }) => {
  const { country, city, doctors, googleRating, googleUserRatingsTotal, hairTransplantsPricings, rating, street, clinicRef, regularTreatmentsPrices, userRatingsTotal, _id } = data;
  const dispatch = useDispatch();
  const [base64, setBase64] = useState("");

  useEffect(() => {
    const asyncFunc = async () => {
      await fetchImageAsBase64(data.clinicRef?.files?.profile[0]?.fileUrl);
    }

    asyncFunc();
  }, [data.clinicRef?.files?.profile[0]?.fileUrl])

  async function fetchImageAsBase64(url: string | undefined) {
    if (!url) return "";
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const blob = await response.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        const base64data = reader.result;
        setBase64(base64data as string);
      }
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  }

  const renderSliderGallery = () => {
    return (
      <div className="flex-shrink-0 p-3 w-full sm:w-64 ">
        {
          base64 != "" &&
          <GallerySlider ratioClass="aspect-w-1 aspect-h-1" galleryImgs={[base64]} className="w-full h-full rounded-2xl overflow-hidden will-change-transform" uniqueID={`PropertyCardH_${_id}`} href={`${data.clinicRef._id}/${data._id}`} />
        }

        {true && <SaleOffBadge className="absolute left-5 top-5 !bg-orange-500" />}
      </div>
    );
    //   <div className="test">
    //     <img src={base64} alt={"profile picture"} />
    //     {/* <img src={data.clinicRef?.files?.profile[0]?.fileUrl} alt={"profile picture"} /> */}
    //     {/* <img src="https://api.haironow.com/api/file/public/66575d6b31ff25136eea4833/profile/1721481363475.jpg" alt="Profile Image"></img> */}
    //   </div>
    // );
  };

  const renderTienIch = () => {
    return (
      <div className="inline-grid grid-cols-3 gap-2">
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-expand-arrows-alt text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">{data.clinicRef.description}</span>
        </div>

        {/* ---- */}
        {/* <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-bath text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">3 baths</span>
        </div> */}

        {/* ---- */}
        {/* <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-expand-arrows-alt text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">1200 Sq. Fit</span>
        </div> */}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
        <div className="space-y-4 w-full">
          <div className="inline-flex space-x-3">
            <Badge
              name={
                <div className="flex items-center">
                  <i className="text-sm las la-share-alt"></i>
                  <span className="ml-1">#1 doctors</span>
                </div>
              }
            />
            <Badge
              name={
                <div className="flex items-center">
                  <i className="text-sm las la-user-friends"></i>
                  <span className="ml-1">cheapest price around</span>
                </div>
              }
              color="yellow"
            />
          </div>
          <div className="flex items-center space-x-2">
            {true && <Badge name="ADS" color="green" />}
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-2">{data.clinicRef.name}</span>
            </h2>
          </div>
          {renderTienIch()}
          <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div>
          <div className="flex w-full justify-between items-end">
            <StartRating reviewCount={googleRating} point={0} />
            <span className="flex items-center justify-center px-3 py-2 border border-secondary-500 rounded leading-none text-base font-medium text-secondary-500">{`${hairTransplantsPricings.hair.priceRangeMin}$-${hairTransplantsPricings.hair.priceRangeMax}$`}</span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-PropertyCardH group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow ${className}`} data-nc-id="PropertyCardH">
      <Link to={`${data.clinicRef._id}/${data._id}`} className="absolute inset-0"></Link>
      <div className="h-full w-full flex flex-col sm:flex-row sm:items-center">
        {renderSliderGallery()}
        {renderContent()}
      </div>
      <BtnLikeIcon colorClass={` bg-neutral-100 dark:bg-neutral-700 hover:bg-neutral-200 hover:bg-opacity-70 text-neutral-6000 dark:text-neutral-400`} isLiked={true} className="absolute right-5 top-5 sm:right-3 sm:top-3 " />
    </div>
  );
};

export default ClinicCard;
