import { FC, useMemo, useState } from "react";
import { useWizard } from "react-use-wizard";
import Input from "hairo/shared/Input/Input";
import { onboardingWizardProps } from "../ClinicOnBoardingWizardPage";
import { Branch } from "hairo/models/interfaces/branch.model";
import Select from "hairo/shared/Select/Select";
import { DoctorsInput } from "./doctors-input/doctors.input";

export const OnBoardingStep2Socials: FC<onboardingWizardProps> = (props: onboardingWizardProps) => {
    const { handleStep, previousStep, nextStep } = useWizard();
    const [formData, setFormData] = useState<Partial<Branch>>({
        city: props.onboardingRequest.branches?.city ?? "",
        country: props.onboardingRequest.branches?.country ?? "",
        street: props.onboardingRequest.branches?.street ?? "",
        doctors: props.onboardingRequest.branches?.doctors ?? [],
    });

    const isFromValid = useMemo(() => {
        console.log(formData);
        return formData.city != "" && formData.country != "" && formData.street != undefined && formData.doctors?.length;
    }, [formData])

    // Attach an optional handler
    handleStep(() => {
    });

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleDoctorsChange = (value: Array<{ name: string }>) => {
        console.log(value);
        setFormData({
            ...formData,
            ["doctors"]: value,
        });
    };

    return (
        <>
            <div className="title">
                Branch Details
            </div>
            {/* <button onClick={() => previousStep()}>Previous ⏮️</button> */}
            <form className="grid grid-cols-1 gap-6" onSubmit={() => {
                props.setOnboardingRequest({ ...props.onboardingRequest, branches: { ...props.onboardingRequest.branches, ...formData } })
                nextStep();
            }}>
                <label className="flex" style={{ alignItems: "center" }}>
                    <span className="text-neutral-800 dark:text-neutral-200 whitespace-nowrap">City</span>
                    <Input value={formData.city} type="text" name="city" className="mt-1" onChange={handleChange} />
                </label>
                <label className="flex" style={{ alignItems: "center" }}>
                    <span className="text-neutral-800 dark:text-neutral-200 whitespace-nowrap">Country</span>
                    <Input value={formData.country} type="text" name="country" className="mt-1" onChange={handleChange} />
                </label>
                <label className="flex" style={{ alignItems: "center" }}>
                    <span className="text-neutral-800 dark:text-neutral-200 whitespace-nowrap">Street</span>
                    <Input value={formData.street} type="text" name="street" className="mt-1" onChange={handleChange} />
                </label>
                <DoctorsInput doctors={props.onboardingRequest.branches?.doctors} onDoctorsChange={handleDoctorsChange} />
                <div className="flex" style={{ justifyContent: "space-between" }}>
                    <button className={`wizardBtn ttnc-ButtonPrimary disabled:bg-opacity-70 bg-black hover:bg-primary-700 text-neutral-50`}
                        style={{ width: "150px" }}
                        onClick={() => previousStep()}>Previous </button>
                    <button
                        className={`wizardBtn ttnc-ButtonPrimary disabled:bg-opacity-70 bg-black hover:bg-primary-700 text-neutral-50`}
                        type="submit"
                        style={{ width: "150px" }}
                        disabled={!isFromValid}
                    >
                        Next
                    </button>
                </div>
            </form >
        </>
    );
}