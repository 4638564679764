import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import MyRouter from "hairo/routers/index";
import { LoginService } from "hairo/services/login.service";
import { setIslogged } from "hairo/store/global-states/global-states.slice";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import { HairoLoadingComponent } from "hairo/shared/HairoLoading/hairo-loading.component";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const loginService = new LoginService();
  const dispatch = useDispatch();
  useEffect(() => {
    const asyncCallback = async () => {
      const isLogged = await loginService.isLoggedIn();

      if (isLogged) {
        dispatch(setIslogged(true));
      } else {
        dispatch(setIslogged(false));
      }
      setIsLoading(false);
    }
    asyncCallback();
  }, []);

  return (
    <div style={{ height: "100%" }} className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      {
        isLoading ?
          <HairoLoadingComponent /> :
          <>
            <MyRouter />
            <ToastContainer />
          </>
      }
    </div>
  );
}

export default App;
