import React, { useState } from 'react';
import Input from "hairo/shared/Input/Input";
import ButtonCircle from "hairo/shared/Button/ButtonCircle";
import NcImage from '../NcImage/NcImage';
import rightImg from "hairo/images/SVG-subcribe2.png";
import Badge from '../Badge/Badge';
import Swal from 'sweetalert2'

export interface SectionSubscribe2Props {
  className?: string;
}

const EmailForm: React.FC<SectionSubscribe2Props> = (props: SectionSubscribe2Props) => {
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(true);

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsValid(validateEmail(newEmail));
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (isValid && email) {
      console.log('Valid email submitted:', email);
      Swal.fire({
        title: 'Your request has been successfully received',
        icon: 'success',
        confirmButtonText: 'Confirm',
        confirmButtonColor: "#3085d6"
      })
      //add logic for what to do with a valid email
    } else {
      console.log('Invalid email');
      setIsValid(false);
    }
  };

  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${props.className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl">collaborate with us! 🎉</h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
          Join us and get more patients!
        </span>
        <ul className="space-y-4 mt-10">
          <li className="flex items-center space-x-4">
            <Badge name="01" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Get more leads
            </span>
          </li>
          <li className="flex items-center space-x-4">
            <Badge color="red" name="02" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              stop spending houres on price offers
            </span>
          </li>
        </ul>
        <form className="mt-10 relative max-w-sm">
          <Input
            required
            aria-required
            placeholder="Enter your email"
            type="email"
            rounded="rounded-full"
            value={email}
            onChange={handleInputChange}
            className={!isValid ? 'border-red-500' : ''}
          />
          {!isValid && (
            <p className="text-red-500 text-sm mt-1">Please enter a valid email address.</p>
          )}
          <ButtonCircle
            className="absolute transform top-1/2 -translate-y-1/2 right-[5px]"
            onClick={handleSubmit}
          >
            <i className="las la-arrow-right text-xl"></i>
          </ButtonCircle>
        </form>
      </div>
      <div className="flex-grow">
        <NcImage src={rightImg} />
      </div>
    </div>
  );
};

export default EmailForm;