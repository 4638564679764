// src/features/counter/counterSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Procedure } from 'hairo/models/enums/procedure.enum';
import { Clinic } from '../../models/response/clinics.response';
import { Details } from 'hairo/models/response/details.respons';
import { PreferredCurrency } from 'hairo/models/enums/preferred-currency.enum';

export type ClinicsFiltersPayload<K extends keyof ClinicsFilters> = {
    key: K;
    value: ClinicsFilters[K];
};

export interface ClinicsFilters {
    priceRange: [number, number];
    location: string;
    treatmentType: Procedure;
}

interface GlobalAppStates {
    clinicsFilters: ClinicsFilters;
    shouldCheckAuth: boolean;
    currentClinic: Details | undefined;
    // currentClinic: Clinic | undefined;
    isClinic: boolean;
    isLogged: boolean;
    redirectAfterLogin: string;
    preferredCurrency: PreferredCurrency;
}

const initialState: GlobalAppStates = {
    clinicsFilters: {
        priceRange: [100, 10000],
        location: '',
        treatmentType: "" as any,
    },
    currentClinic: undefined,
    shouldCheckAuth: false,
    isClinic: false,
    isLogged: false,
    redirectAfterLogin: "",
    preferredCurrency: localStorage.getItem('preferredCurrency') as PreferredCurrency || PreferredCurrency.USD,
};

const appSlice = createSlice({
    name: 'global app states',
    initialState,
    reducers: {
        setClinicsFilter<K extends keyof ClinicsFilters>(state: GlobalAppStates, action: PayloadAction<ClinicsFiltersPayload<K>>) {
            const { key, value } = action.payload;
            state.clinicsFilters[key] = value;
        },

        setClinicsFilters(state: GlobalAppStates, action: PayloadAction<ClinicsFilters>) {
            state.clinicsFilters = action.payload;
        },

        setShouldCheckAuth(state: GlobalAppStates, action: PayloadAction<boolean>) {
            state.shouldCheckAuth = action.payload;
        },
        setCurrentClinic(state: GlobalAppStates, action: PayloadAction<Details>) {
            state.currentClinic = action.payload;
        },
        setIslogged(state: GlobalAppStates, action: PayloadAction<boolean>) {
            state.isLogged = action.payload;
        },
        setIsClinic(state: GlobalAppStates, action: PayloadAction<boolean>) {
            state.isClinic = action.payload;
        },
        setRedirectAfterLogin(state: GlobalAppStates, action: PayloadAction<string>) {
            state.redirectAfterLogin = action.payload;
        },
        setPreferredCurrency: (state, action: PayloadAction<PreferredCurrency>) => {
            state.preferredCurrency = action.payload;
            localStorage.setItem('preferredCurrency', action.payload);
          },
    },
});

export const { setClinicsFilter, setClinicsFilters, setShouldCheckAuth, setCurrentClinic, setIslogged, setIsClinic, setRedirectAfterLogin,setPreferredCurrency } = appSlice.actions;
export default appSlice.reducer;
