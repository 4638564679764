import React, { FC, useState } from "react";
import Input from "hairo/shared/Input/Input";
import plusIcon from "./plus.svg";
import trashIcon from "./trash.svg";

export interface DoctorInputProps {
    onDoctorsChange: (value: Array<{ name: string }>) => void;
    doctors: {
        name: string;
    }[];
}

export const DoctorsInput: FC<DoctorInputProps> = (props: DoctorInputProps) => {
    const [doctors, setDoctors] = useState<Array<{ name: string }>>(props.doctors ?? []);
    const [currentDoctor, setCurrentDoctor] = useState<{ name: string }>({ name: "" });

    function handleChange(value: string) {
        setCurrentDoctor({ name: value });
    }

    function handlePlus(event: any) {
        event.preventDefault();
        
        if (currentDoctor.name.trim()) {
            const doctorsClone = [...doctors, currentDoctor];
            setDoctors(doctorsClone);
            setCurrentDoctor({ name: "" });
            props.onDoctorsChange(doctorsClone);
        }
    }

    function handleRemove(index: number, event: any) {
        event.preventDefault();

        const doctorsClone = doctors.filter((_, i) => i !== index);
        setDoctors(doctorsClone);
        props.onDoctorsChange(doctorsClone);
    }

    return (
        <div className="flex flex-col w-full space-y-4">
            <label className="text-neutral-800 dark:text-neutral-200 font-medium">
                Doctors
            </label>
            <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2">
                <div className="relative w-full">
                    <Input
                        value={currentDoctor.name}
                        type="text"
                        placeholder="Enter doctor's name"
                        className="w-full pr-10"
                        onChange={(event) => handleChange(event.target.value)}
                    />
                    <button
                        onClick={handlePlus}
                        className="absolute right-2 top-1/2 transform -translate-y-1/2"
                    >
                        <img
                            src={plusIcon}
                            alt="Add doctor"
                            className="w-6 h-6 cursor-pointer"
                        />
                    </button>
                </div>
            </div>
            {doctors.map((doctor, index) => (
                <div key={index} className="relative w-full">
                    <Input
                        type="text"
                        value={doctor.name}
                        disabled={true}
                        className="w-full pr-10"
                        style={{ backgroundColor: "#c9c6c638" }}
                    />
                    <button
                        onClick={(event) => handleRemove(index, event)}
                        className="absolute right-2 top-1/2 transform -translate-y-1/2"
                    >
                        <img
                            src={trashIcon}
                            alt="Remove doctor"
                            className="w-6 h-6 cursor-pointer"
                        />
                    </button>
                </div>
            ))}
        </div>
    );
};

export default DoctorsInput;