import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Wizard, useWizard } from 'react-use-wizard';
import Stepper from '@mui/material/Stepper';
import { Step, StepLabel } from '@mui/material';
import { toast } from "react-toastify";
import { OnBoardingStep1Details } from './step-1-details/step-1-details.component';
import { OnBoardingStep2Socials } from './step-2-socials/step-2-socials.component';
import { OnBoardingStep3Prices } from './step-3-prices/step-3-prices.component';
import { ClinicsOnboardingRequest } from 'hairo/models/request/clinics-onboarding.request';
import { ClinicsService } from 'hairo/services/clinics.service';
import './ClinicOnBoardingWizardPage.style.scss';

export interface onboardingWizardProps {
    onboardingRequest: ClinicsOnboardingRequest,
    setOnboardingRequest: Dispatch<SetStateAction<ClinicsOnboardingRequest>>
    onSubmit: (onboardingRequest: ClinicsOnboardingRequest) => void
}
export const ClinicOnBoardingWizardPage = () => {
    const clinicService = useRef(new ClinicsService()).current;
    const [onboardingRequest, setOnboardingRequest] = useState<ClinicsOnboardingRequest>(
        {
            branches: [] as any,
            description: "",
            name: "",
            yearsOfActivity: 0,
        }
    );
    const [completed, setCompleted] = useState<{ [k: number]: boolean }>({});
    const [stepIndex, setStepIndex] = useState(0);

    const onSubmit = async (onboardingform: ClinicsOnboardingRequest) => {
        const clone = { ...onboardingform };
        (clone.branches as any) =[clone.branches as any];
        (clone.branches as any)[0].currency = "USD"
        console.log("onSubmit");
        try {
            const response = await clinicService.submitOnboardingWizard(clone);
            console.log(response);
            if (response.status) {
                toast.success("Register done successfully", { position: "bottom-left", autoClose: 1000 })

            } else {
                console.log(response.additional)
                toast.error(response.additional as string, { position: "bottom-left", autoClose: 1000 })
            }
        } catch (e) {
            toast.error(e as string, { position: "bottom-left", autoClose: 1000 })
        }
    }

    const props = { onboardingRequest, setOnboardingRequest, onSubmit }

    return (
        <div className='ClinicOnBoardingWizardPageContainer'>
            <div className={`wizardContainer step-${stepIndex}`}>
                <Wizard onStepChange={(stepIndex: number) => setStepIndex(stepIndex)}>
                    <OnBoardingStep1Details {...props} />
                    <OnBoardingStep2Socials {...props} />
                    <OnBoardingStep3Prices {...props} />
                </Wizard>
            </div>
            <div className="stepsContainer">
                <Stepper alternativeLabel activeStep={stepIndex}>
                    <Step key={"Clinic Details"} completed={completed[0]}>
                        <StepLabel>{"Clinic Details"}</StepLabel>
                    </Step>
                    <Step key={"Branch Details"} completed={completed[1]}>
                        <StepLabel>{"Branch Details"}</StepLabel>
                    </Step>
                    <Step key={"Pricing"} completed={completed[2]}>
                        <StepLabel>{"Pricing"}</StepLabel>
                    </Step>
                </Stepper>
            </div>
        </div >
    )
}