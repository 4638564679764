"use client";

import React, { FC, useState } from "react";
import HairTransplantSearchForm from "./HairTransplantSearchForm/HairTransplantSearchForm";
import { ClinicsFilters } from "hairo/store/global-states/global-states.slice";
import { useSelector } from "react-redux";
import { RootState } from "hairo/store/store";

export interface HeroRealEstateSearchFormProps {
  className?: string;
}

const HomePageSearchForm: FC<HeroRealEstateSearchFormProps> = ({
  className = "",
}) => {
  const clinicFilters = useSelector((state: RootState) => state.globalStates.clinicsFilters);

  return (
    <div
      className={`nc-HeroRealEstateSearchForm w-full max-w-6xl py-5 lg:py-0 ${className}`}
    >
     <HairTransplantSearchForm  clinicFilters={clinicFilters}/>
    </div>
  );
};

export default HomePageSearchForm;
