import React, { FC } from "react";
import Logo from "hairo/shared/Logo/Logo";
import MenuBar from "hairo/shared/MenuBar/MenuBar";
import SwitchDarkMode from "hairo/shared/SwitchDarkMode/SwitchDarkMode";
import { Link, useLocation } from "react-router-dom";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import AvatarDropdown from "components/Header/AvatarDropdown";
import LangDropdown from "../inputs/concrets/LangDropdown";
import CurrencyDropdown from "../inputs/concrets/CurrencyDropdown";

export interface MainNav2Props {
  className?: string;
}

const MainNav2: FC<MainNav2Props> = ({ className = "" }) => {
  const location = useLocation();
  const isLogginPage = location.pathname.includes('login') || location.pathname.includes('signup');

  return (
    <div className={`nc-MainNav1 nc-MainNav2 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-3 sm:space-x-8 lg:space-x-10">
          <Logo />
          <div className="hidden lg:block h-10 border-l border-neutral-300 dark:border-neutral-500"></div>
          <div className="hidden lg:block">
            {/* <DropdownTravelers /> */}
          </div>
        </div>

        {/* <div className="lg:hidden flex-[3] max-w-lg !mx-auto md:px-3">
          <HeroSearchForm2MobileFactory />
        </div> */}
        {
          !isLogginPage &&
          <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
            <div className="hidden items-center lg:flex space-x-1">
              <CurrencyDropdown />
              <LangDropdown />
              {/* <Link
              to="/add-listing-1"
              className="
                text-opacity-90
                group px-4 py-2 border border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            >
              List your property
            </Link> */}

              <div></div>
              {/* <SwitchDarkMode /> */}
              <div className="pr-1.5">
                {/* <NotifyDropdown className="-ml-2 xl:-ml-1" /> */}
              </div>
              <AvatarDropdown />
            </div>
            {/* <div className="flex items-center space-x-2 lg:hidden">
            <NotifyDropdown />
            <AvatarDropdown />
            <MenuBar />
          </div> */}
          </div>
        }
      </div>
    </div>
  );
};

export default MainNav2;
