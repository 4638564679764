import React, { FC, ReactNode, useEffect, useState } from "react";
import { DEMO_STAY_LISTINGS } from "hairo/data/listings";
import { StayDataType } from "hairo/data/types";
import ButtonPrimary from "hairo/shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter/HeaderFilter";
import ClinicCard from "hairo/components/ClinicsViewPage/ClinicViewSection/ClinicCard/ClinicCard";
import Pagination from "hairo/shared/Pagination/Pagination";
import { Clinic } from "hairo/models/response/clinics.response";
import ClinicsMock from "hairo/data/jsons/hairo/clinics.json";

// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);
//
export interface SectionGridFeatureClinicsProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const SectionGridFeatureClinics: FC<SectionGridFeatureClinicsProps> = ({ stayListings = DEMO_DATA, gridClass = "", heading = "Most popular Clincs", subHeading = "Popular clincs in your country", headingIsCenter, tabs = ["New York", "Tokyo", "Paris", "London"] }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState<any[]>([]);
  const ITEMS_PER_PAGE = 4;

  useEffect(() => {
    if (DEMO_DATA) {
      const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
      const endIndex = startIndex + ITEMS_PER_PAGE;
      console.log(startIndex)
      console.log(endIndex)
      // Slice the data array to get the current page data
      const pageData = ClinicsMock.slice(startIndex, endIndex);
      console.log(pageData);
      // Update the currentData state with the sliced data
      setCurrentData(pageData);
    }
  }, [DEMO_DATA, currentPage])

  const renderCard = (data:any) => {
    return <ClinicCard data={data} />;
  };

  return (
    <div className="nc-SectionGridFeatureProperty relative">
      <HeaderFilter tabActive={"New York"} subHeading={subHeading} tabs={tabs} heading={heading} onClickTab={() => { }} />
      <div className={`grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-2 ${gridClass}`}>{currentData.map((data) => renderCard(data))}</div>
      <div className="flex mt-16 justify-center items-center">
        <div className="flex mt-16 justify-center items-center">
          {DEMO_DATA?.length && <Pagination pages={DEMO_DATA?.length / 4} onPageChanged={(page: number) => setCurrentPage(page)} />}
        </div>
      </div>
    </div>
  );
};

export default SectionGridFeatureClinics;
