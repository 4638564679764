import { CustomLink } from "hairo/data/types";
import React, { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { pages } from "hairo/routers";
import twFocusClass from "utils/twFocusClass";

export type PaginationItem = { page: number, onClick: () => void }

export interface PaginationProps {
  className?: string;
  pages: number;
  onPageChanged: (page: number) => void
}

const Pagination: FC<PaginationProps> = (props: PaginationProps) => {
  const [pagination, setPagination] = useState<PaginationItem[]>([]);
  const [selectedPage, setSelectedPage] = useState(1)
  const selectedClass = `cursor-pointer inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white ${twFocusClass()}`;
  const notSelectedClass = `cursor-pointer inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`;

  useEffect(() => {
    const paginationArray = [];
    for (let index = 1; index <= props.pages; index++) {
      paginationArray.push({
        page: index,
        onClick: () => {
          setSelectedPage(index)
          props.onPageChanged(index);
        }
      })
    }
    setPagination(paginationArray);
  }, [props.pages])

  const renderItem = (page: PaginationItem, index: number) => {
    if (index === 0) {
      // RETURN ACTIVE PAGINATION
      return (
        <div onClick={() => page.onClick()}
          key={index}
          className={selectedPage == index + 1 ? selectedClass : notSelectedClass}
        >
          {page.page}
        </div>
      );
    }
    // RETURN UNACTIVE PAGINATION
    return (
      <div onClick={() => page.onClick()} className={selectedPage == index + 1 ? selectedClass : notSelectedClass}>
        {page.page}
      </div>
    );
  };

  return (
    <nav
      className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${props.className}`}
    >
      {pagination.map(renderItem)}
    </nav>
  );
};

export default Pagination;
