import React, { useState } from "react";
import ModalReserveMobile from "./ModalReserveMobile";
import ModalSelectDate from "components/ModalSelectDate";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ButtonPrimary from "hairo/shared/Button/ButtonPrimary";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoginService } from "hairo/services/login.service";
import { ClinicsService } from "hairo/services/clinics.service";
import { RootState } from "hairo/store/store";
import { setRedirectAfterLogin } from "hairo/store/global-states/global-states.slice";
import { toast } from "react-toastify";

const MobileFooterSticky = () => {
  const [startDate] = useState<Date | null>(new Date("2023/02/06"));
  const [endDate] = useState<Date | null>(new Date("2023/02/23"));
  const router = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isContactUsLoading, setIsContactUsLoading] = useState(false);
  const loginSerivec = new LoginService();
  const clinicService = new ClinicsService();
  const clinicData = useSelector((state: RootState) => state.globalStates.currentClinic);

  return (
    <div className="block lg:hidden fixed bottom-0 inset-x-0 py-2 sm:py-3 bg-white dark:bg-neutral-800 border-t border-neutral-200 dark:border-neutral-6000 z-40">
      <div className="container flex items-center justify-between">
        <div className="">
          <span className="block text-xl font-semibold">
            <span className="ml-1 text-sm font-normal text-neutral-500 dark:text-neutral-400">
            </span>
          </span>
          {/* <ModalSelectDate
            renderChildren={({ openModal }) => (
              <span
                onClick={openModal}
                className="block text-sm underline font-medium"
              >
                {converSelectedDateToString([startDate, endDate])}
              </span>
            )}
          /> */}
        </div>
        <ButtonPrimary onClick={async () => {
          setIsContactUsLoading(true);
          const isLogged = await loginSerivec.isLoggedIn();
          setIsContactUsLoading(false);
          if (isLogged) {
            if (clinicData?._id) {
              const sendLead = await clinicService.sendLead(clinicData._id, clinicData?._id)
              if (!sendLead.res) {
                toast.error(sendLead.message, { position: "bottom-left", autoClose: 1000 })
              } else {
                toast.success("Lead sent", { position: "bottom-left", autoClose: 1000 })
              }
              console.log(sendLead);
              console.log("mail");
            } else {
              toast.error("Lead failed to sent", { position: "bottom-left", autoClose: 1000 })
            }
          } else {
            dispatch(setRedirectAfterLogin(location.pathname))
            router("/login", { replace: true })
          }
        }}>Lets Talk</ButtonPrimary>
      </div>
    </div>
  );
};

export default MobileFooterSticky;
