import { LoginResponse } from "hairo/models/response/login.response";
import axios, { AxiosError } from "axios";
import { ClinicsRequest } from "hairo/models/request/clinics.request";
import { Clinic, ClinicsResponse } from "hairo/models/response/clinics.response";
import { Details } from "hairo/models/response/details.respons";
import { ClinicsOnboardingRequest } from "hairo/models/request/clinics-onboarding.request";
import { HairoResponse } from "hairo/models/response/generic/hairo-response.model";

export class ClinicsService {
    async getClinics(clinicRequest: ClinicsRequest): Promise<Clinic[] | null> {
        if (clinicRequest.city == "") {
            delete clinicRequest.city;
        }
        try {
            const response = await axios.post<ClinicsResponse>("https://api.haironow.com/api/clinic", clinicRequest, { withCredentials: true });

            if (response.status !== 200) return null;

            const data: ClinicsResponse = await response.data;
            return data.additional;
        } catch (error) {
            console.error("Error:", error);
            return null;
        }
    }

    async getClinicDetails(clinicId: string, branchId: string): Promise<Details | null> {
        try {
            const response = await axios.get(`https://api.haironow.com/api/clinic/branch/${clinicId}/${branchId}/details`, { withCredentials: true });

            if (response.status !== 200) return null;

            const data = await response.data;
            return data.additional;
        } catch (error) {
            console.error("Error:", error);
            return null;
        }
    }

    async sendLead(clinicId: string, branchId: string): Promise<{ res: boolean, message?: string }> {

        try {
            const response = await axios.post<HairoResponse<any>>(`https://api.haironow.com/api/clinic/send-lead`, {
                id: clinicId,
                branchId: branchId
            }, { withCredentials: true });
            console.log(response.data.additional);
            return { res: response.status === 200, message: response.data.additional }
        } catch (error) {
            console.error("Error:", (error as AxiosError<any>));
            return { res: false, message: (error as AxiosError<any>).response?.data.additional }
        }
    }

    async submitOnboardingWizard(onboardingform: ClinicsOnboardingRequest): Promise<{ status: boolean, additional: string }> {

        try {
            const response = await axios.post<HairoResponse<any>>(`https://api.haironow.com/api/clinic/wizard-details`, onboardingform,
                { withCredentials: true });
            console.log(response);
            return { status: response.data.result, additional: response.data.additional }
        } catch (error: any) {
            console.log(error);
            console.error("Error:", error);
            return { status: false, additional: error.response.data.additional as string }
        }
    }

}