import { LoginResponse } from "hairo/models/response/login.response";
import axios from "axios";

export class LoginService {
    async isLoggedIn(): Promise<boolean> {

        try {
            const response = await axios.get("https://api.haironow.com/api/user/is-logged-in", { withCredentials: true });

            if (response.status !== 200) return false;

            const data: LoginResponse = await response.data;
            return data.result;
        } catch (error) {
            console.error("Error:", error);
            return false;
        }
    }

    async login(email: string, password: string): Promise<boolean> {
        try {
            const response = await axios.post("https://api.haironow.com/api/user/login", {
                email: email,
                password: password
            }, { withCredentials: true });

            if (response.status !== 200) return false;

            const data: LoginResponse = await response.data;
            return data.result;
        } catch (error) {
            return false;
        }
    }

    async logout(): Promise<boolean> {
        try {
            const response = await axios.post("https://api.haironow.com/api/user/logout", null, { withCredentials: true });

            if (response.status !== 200) return false;

            const data: LoginResponse = await response.data;
            return data.result;
        } catch (error) {
            return false;
        }
    }
}