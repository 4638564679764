import React, { FC } from "react";
import imagePng from "hairo/images/logos/nomal/hair-transplant.jpg";
import HomePageSearchForm from "./HomePageSearchForm/HomePageSearchForm";
import HeroSearchForm2Mobile from "./HomePageSearchForm/HeroSearchForm2Mobile/HeroSearchForm2Mobile";

export interface HomePageProps {
  className?: string;
  children?: React.ReactNode;
}

const HomePageSearchSection: FC<HomePageProps> = ({ className = "", children }) => {
  return (
    <>
      <div
        className={`nc-SectionHero2 relative ${className}`}
        data-nc-id="SectionHero2"
      >
        <div className="absolute inset-y-0 w-5/6 xl:w-3/4 right-0 flex-grow">
          <img
            className="absolute inset-0 object-cover w-full h-full"
            src={imagePng}
            alt="hero"
          />
        </div>
        <div className="relative py-14 lg:py-20">
          <div className="relative inline-flex">
            <div className="w-screen right-20 md:right-52 inset-y-0 absolute bg-[#77AECC]"></div>
            <div className="relative max-w-3xl inline-flex flex-shrink-0 flex-col items-start py-16 sm:py-20 space-y-8 sm:space-y-10 text-white">
              {children ? (
                children
              ) : (
                <h2 className="font-semibold text-2xl lg:text-2xl xl:text-7xl !leading-[110%]">
                  Find The Best Hair Transplant Clinics
                </h2>
              )}
            </div>
          </div>

          <div className="hidden lg:block lg:mt-20 w-full">
            <HomePageSearchForm />
          </div>
        </div>
      </div>
      <div className="block lg:hidden">
        <HeroSearchForm2Mobile />
      </div>
    </>
  );
};

export default HomePageSearchSection;
