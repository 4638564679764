import React, { Dispatch, FC, useEffect, useState } from "react";
import { Procedure } from "hairo/models/enums/procedure.enum";
import { ClinicsFilters, setClinicsFilters } from "hairo/store/global-states/global-states.slice";
import TreatmentTypeSelect from "hairo/components/inputs/concrets/TreatmentTypeSelect";
import PriceRangeInput from "hairo/components/inputs/concrets/PriceRangeInput";
import LocationInput from "hairo/components/inputs/concrets/LocationInput";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "hairo/store/store";

export interface HairTransplantSearchFormProps {
  clinicFilters?: ClinicsFilters;
  shouldSearch: boolean;
}

const MobileSearchForm: FC<HairTransplantSearchFormProps> = (props: HairTransplantSearchFormProps) => {
  //
  const [fieldNameShow, setFieldNameShow] = useState<
    "location" | "dates" | "guests"
  >("location");
  //
  const [formData, setFormData] = useState<ClinicsFilters>({
    location: "Israel",
    treatmentType: Procedure.beard,
    priceRange: [0, 0],
  });
  const [locationInputTo, setLocationInputTo] = useState("");
  const [startDate] = useState<Date | null>(new Date("2023/02/06"));
  const [endDate] = useState<Date | null>(new Date("2023/02/23"));
  const [shouldShowLocationTooltip, setShouldShowLocationTooltip] = useState(false);
  const [shouldShowTreatmentTooltip, setShouldShowTreatmentTooltip] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const router = useNavigate();

  useEffect(() => {
    console.log(formData.location);
    if (formData.location) {
      setShouldShowLocationTooltip(false);
    }
  }, [formData.location]);

  useEffect(() => {
    console.log(formData.treatmentType);
    if (formData.treatmentType) {
      setShouldShowTreatmentTooltip(false);
    }
  }, [formData.treatmentType]);

  useEffect(() => {
    if (props.shouldSearch) {
      if (!formData.location || !formData.treatmentType) {
        console.log("empty data");
        setShouldShowLocationTooltip(!formData.location);
        setShouldShowTreatmentTooltip(!formData.treatmentType)
      } else {
        setShouldShowLocationTooltip(false);
        setShouldShowTreatmentTooltip(false)
        dispatch(setClinicsFilters(formData))
        console.log(formData);
        router("/listing-clinics");
      }
    }
  }, [props.shouldSearch])


  return (
    <div>
      <div className="w-full space-y-5">
        {/*  */}
        {/* {renderInputLocation()} */}
        <LocationInput value={props.clinicFilters?.location ?? ""} onChange={(location: string) => setFormData({ ...formData, location: location })} shouldShowTooltip={shouldShowLocationTooltip} className="flex-[1.5]" />

        <TreatmentTypeSelect shouldShowTooltip={shouldShowTreatmentTooltip} value={props.clinicFilters?.treatmentType} onChange={(treatmentType: Procedure) => {
          console.log("treatmentType");
          console.log(treatmentType);
          console.log(formData);
          setFormData({ ...formData, treatmentType: treatmentType });
        }} />
        <PriceRangeInput value={props.clinicFilters?.priceRange} isSearchVisible={false} onChange={(price: [number, number]) => {
          console.log("price");
          console.log(price);
          console.log(formData);
          setFormData({ ...formData, priceRange: price })
        }
        } onSearch={() => null} />
        {/* <div className="pr-2 xl:pr-4" onClick={() => {
          console.log(formData)
          dispatch(setClinicsFilters(formData))
          console.log(formData);
        }}>
        </div> */}

        {/*  */}
        {/* {renderInputDates()} */}
        {/*  */}
        {/* {renderInputGuests()} */}
      </div>
    </div>
  );
};

export default MobileSearchForm;
