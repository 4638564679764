import { configureStore } from '@reduxjs/toolkit';
import globalStateReducer from './global-states/global-states.slice';
import languageReducer from './language/languageSlice';

const store = configureStore({
  reducer: {
    globalStates: globalStateReducer,
    language: languageReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
