import { FC, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useWizard } from "react-use-wizard";
import Input from "hairo/shared/Input/Input";
import { onboardingWizardProps } from "../ClinicOnBoardingWizardPage";
import { Branch } from "hairo/models/interfaces/branch.model";
import TreatmentPricingForm, { TreatmentsState } from "./price-table.component";

export const OnBoardingStep3Prices: FC<onboardingWizardProps> = (props: onboardingWizardProps) => {
    const { previousStep } = useWizard();
    const [formData, setFormData] = useState<Partial<Branch>>();
    const [isValidForm, setIsValidForm] = useState(false);
    const navigator = useNavigate();
    return (
        <>
            <div className="title">
                Pricing
            </div>
            <form className="grid grid-cols-1 gap-6" onSubmit={(e) => {
                const updatedForm = { ...props.onboardingRequest, branches: { ...props.onboardingRequest.branches, ...formData} };
                props.setOnboardingRequest(updatedForm)
                e.preventDefault();
                props.onSubmit(updatedForm);

                // navigator("/clinic-onboarding", { replace: true });
            }}>
                <TreatmentPricingForm onUpdate={(treatments: TreatmentsState) => {
                    setFormData({
                        regularTreatmentsPrices: {
                            prgf: { price: Number.parseFloat(treatments.prgf.pricePerSession) },
                            prp: { price: Number.parseFloat(treatments.prp.pricePerSession) },
                        },
                        hairTransplantsPricings: {
                            hair: {
                                pricePerFollicle: Number.parseFloat(treatments.hair.pricePerFollicle),
                                priceRangeMin: Number.parseFloat(treatments.hair.priceRangeMin),
                                priceRangeMax: Number.parseFloat(treatments.hair.priceRangeMax),
                            },
                            beard: {
                                pricePerFollicle: Number.parseFloat(treatments.beard.pricePerFollicle),
                                priceRangeMin: Number.parseFloat(treatments.beard.priceRangeMin),
                                priceRangeMax: Number.parseFloat(treatments.beard.priceRangeMax),
                            }
                        }
                    })
                }}
                onValidationChange={(isValid: boolean)=>{
                    setIsValidForm(isValid);
                }} />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <button className={`wizardBtn ttnc-ButtonPrimary disabled:bg-opacity-70 bg-black hover:bg-primary-700 text-neutral-50`}
                        style={{ width: "150px" }}
                        onClick={() => previousStep()}>Previous </button>
                    <button
                        className={`wizardBtn ttnc-ButtonPrimary disabled:bg-opacity-70 bg-black hover:bg-primary-700 text-neutral-50`}
                        type="submit"
                        style={{ width: "150px", placeSelf: "end" }}
                        disabled={!isValidForm}
                    >
                        DONE
                    </button>
                </div>
            </form>
            {/* <button onClick={() => nextStep()}>Next ⏭</button> */}
        </>
    );
}